import ReactDOM from 'react-dom'
import AppRouter from '@/router'
import { UseRequestProvider } from 'ahooks'
import 'moment/locale/zh-cn'
import locale from 'antd/lib/locale/zh_CN'
import { ConfigProvider } from 'antd'
import request from '@/utils/request'
import './index.css'

// 默认情况下momentjs会设置为浏览器运行环境的时区
// https://momentjs.com/timezone/docs/#/using-timezones/default-timezone/
// import moment from 'moment-timezone'
// console.log(moment.tz.guess())

// moment.tz.setDefault('America/Los_Angeles')

const { permissions = [] } = JSON.parse(window.localStorage.getItem('user')) || {}

window.hasPerm = perms => {
  if (JSON.parse(window.localStorage.getItem('user')).isSuperuser) return true
  if (!perms || !perms.length) return true
  if (perms.some(item => !permissions.includes(item))) return false
  return true
}

ReactDOM.render(
  <UseRequestProvider
    value={{
      refreshOnWindowFocus: false,
      requestMethod: param => request(param),
    }}
  >
    <ConfigProvider locale={locale}>
      <AppRouter />
    </ConfigProvider>
  </UseRequestProvider>,
  document.getElementById('root')
)
