import AuthLayout from '@/components/AuthLayout'
import BasicLayout from '@/components/BasicLayout'
import {
  AuditOutlined,
  DashboardOutlined,
  DeploymentUnitOutlined,
  FolderOpenOutlined,
  MessageOutlined,
  PieChartOutlined,
  ProfileOutlined,
  ProjectOutlined,
  SettingOutlined,
  TeamOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons'
import React from 'react'

import contractRouter from '@/pages/app-contract/router'
import externalRouter from '@/pages/app-external/router'
import systemRouter from '@/pages/app-system/router'
import telephoneRouter from '@/pages/app-telephone/router'
export default [
  {
    path: '/public/complaint/h5',
    component: React.lazy(() => import('@/pages/complaint/h5.jsx')),
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        name: '用户登陆',
        exact: true,
        component: React.lazy(() => import('@/pages/auth/login')),
      },
    ],
  },
  {
    path: '/article-preview/:id',
    exact: true,
    hideInMenu: true,
    component: React.lazy(() => import('@/pages/article/preview')),
  },
  {
    path: '/record-preview',
    name: '文档预览',
    icon: ProfileOutlined,
    exact: true,
    component: React.lazy(() => import('@/pages/record-preview')),
  },
  {
    path: '/messages/add',
    name: '新增文章',
    exact: true,
    hideInMenu: true,
    component: React.lazy(() => import('@/pages/article/add')),
  },
  ...contractRouter,
  ...systemRouter,
  ...telephoneRouter,
  ...externalRouter,
  {
    path: '/',
    name: 'app',
    exact: true,
    redirect: '/landing',
  },
  {
    path: '/landing',
    name: 'app',
    exact: true,
    hideInMenu: true,
    component: React.lazy(() => import('@/pages/landing')),
  },
  {
    path: '/',
    component: BasicLayout,
    routes: [
      {
        path: '/dashboard',
        name: '数据概览',
        exact: true,
        icon: DashboardOutlined,
        component: React.lazy(() => import('@/pages/dashboard')),
      },
      {
        path: '/messages',
        name: '通知公告',
        exact: true,
        icon: MessageOutlined,
        component: React.lazy(() => import('@/pages/message/list')),
      },
      {
        path: '/messages/detail/:id',
        name: '通知公告',
        exact: true,
        hideInMenu: true,
        icon: MessageOutlined,
        component: React.lazy(() => import('@/pages/article/detail')),
      },
      {
        path: '/documents',
        name: '文档管理',
        exact: true,
        icon: FolderOpenOutlined,
        perms: ['superuser'],
        component: React.lazy(() => import('@/pages/document/list')),
      },
      {
        path: '/documents/categories',
        name: '分类管理',
        hideInMenu: true,
        exact: true,
        icon: DashboardOutlined,
        component: React.lazy(() => import('@/pages/document/category')),
      },
      {
        path: '/labour',
        name: '用人管理',
        icon: TeamOutlined,
        routes: [
          //   {
          //     path: '/labour/positions',
          //     name: '岗位编制',
          //     exact: true,
          //     component: React.lazy(() => import('@/pages/position')),
          //   },
          {
            path: '/labour/staffs',
            name: '服务人员',
            exact: true,
            component: React.lazy(() => import('@/pages/external-staff')),
          },
          {
            path: '/labour/change-records',
            name: '异动记录',
            exact: true,
            component: React.lazy(() => import('@/pages/change-record/list')),
          },
        ],
      },
      {
        path: '/record',
        name: '记录管理',
        icon: UnorderedListOutlined,
        routes: [
          //   {
          //     path: '/record/attendance-records',
          //     name: '考勤记录',
          //     exact: true,
          //     component: React.lazy(() => import('@/pages/attendance/record/index')),
          //   },
          {
            path: '/record/attendance-records/groups',
            name: '考勤分组',
            exact: true,
            hideInMenu: true,
            component: React.lazy(() => import('@/pages/attendance/group/index')),
          },
          {
            path: '/record/general-records',
            name: '工作日志',
            exact: true,
            component: React.lazy(() => import('@/pages/general-record/list')),
          },
          {
            path: '/record/general-records/templates',
            name: '模板管理',
            hideInMenu: true,
            exact: true,
            component: React.lazy(() => import('@/pages/general-record/template/list')),
          },
          {
            path: '/record/general-records/templates/editor',
            name: '模板编辑',
            hideInMenu: true,
            exact: true,
            component: React.lazy(() => import('@/pages/general-record/template/editor')),
          },
          {
            path: '/record/qa-records',
            name: '品质督导',
            exact: true,
            component: React.lazy(() => import('@/pages/qa')),
          },
          {
            path: '/record/service-applies',
            name: '需求申请',
            exact: true,
            component: React.lazy(() => import('@/pages/service-apply/list')),
          },
          {
            path: '/record/emrecords',
            name: '应急处理',
            exact: true,
            component: React.lazy(() => import('@/pages/emrecord')),
          },
          {
            path: '/record/praises',
            name: '表扬表彰',
            exact: true,
            component: React.lazy(() => import('@/pages/praise/list')),
          },
          {
            path: '/record/complaints',
            name: '投诉建议',
            exact: true,
            component: React.lazy(() => import('@/pages/complaint')),
          },
        ],
      },
      {
        path: '/evaluation',
        name: '评分管理',
        icon: ProjectOutlined,
        exact: true,
        component: React.lazy(() => import('@/pages/evaluation/report/list')),
      },
      {
        path: '/patrol',
        name: '巡更管理',
        icon: DeploymentUnitOutlined,
        routes: [
          {
            path: '/patrol/rounds',
            name: '巡更记录',
            exact: true,
            component: React.lazy(() => import('@/pages/patrol/round')),
          },
          {
            path: '/patrol/rounds/detail',
            name: '巡更列表',
            exact: true,
            hideInMenu: true,
            component: React.lazy(() => import('@/pages/patrol/record/list')),
          },
          {
            path: '/patrol/points',
            name: '巡更点位',
            exact: true,
            component: React.lazy(() => import('@/pages/patrol/point')),
          },
          {
            path: '/patrol/points/groups',
            name: '分组管理',
            exact: true,
            hideInMenu: true,
            component: React.lazy(() => import('@/pages/patrol/group')),
          },
        ],
      },
      {
        path: '/workflow',
        name: '流程管理',
        icon: AuditOutlined,
        perms: ['superuser'],
        routes: [
          {
            path: '/workflow/process-definitions',
            name: '流程定义',
            exact: true,
            component: React.lazy(() => import('@/pages/workflow/process-definition/list')),
          },
          {
            path: '/workflow/dashboard',
            name: '工作台',
            exact: true,
            component: React.lazy(() => import('@/pages/workflow/dashboard')),
          },
          {
            path: '/workflow/modeler',
            name: '流程设计',
            exact: true,
            component: React.lazy(() => import('@/pages/workflow/modeler')),
          },
          {
            path: '/workflow/tasks',
            name: '任务列表',
            exact: true,
            component: React.lazy(() => import('@/pages/workflow/task/list')),
          },
        ],
      },
      {
        path: '/statistics',
        name: '统计分析',
        icon: PieChartOutlined,
        routes: [
          //   {
          //     path: '/statistics/employee',
          //     name: '人员统计',
          //     exact: true,
          //     component: React.lazy(() => import('@/pages/statistic/employee')),
          //   },
          //   {
          //     path: '/statistics/attendance',
          //     name: '考勤统计',
          //     exact: true,
          //     component: React.lazy(() => import('@/pages/statistic/attendance')),
          //   },
          {
            path: '/statistics/evaluation',
            name: '评分统计',
            exact: true,
            component: React.lazy(() => import('@/pages/statistic/evaluation')),
          },
        ],
      },
      {
        path: '/system',
        name: '系统管理',
        icon: SettingOutlined,
        perms: ['superuser'],
        routes: [
          {
            path: '/system/users',
            name: '用户管理',
            exact: true,
            component: React.lazy(() => import('@/pages/app-system/staff')),
          },
          {
            path: '/system/areas',
            name: '区域管理',
            exact: true,
            component: React.lazy(() => import('@/pages/area')),
          },
          {
            path: '/system/deps',
            name: '部门管理',
            exact: true,
            component: React.lazy(() => import('@/pages/dep/list')),
          },
          {
            path: '/system/cost-centers',
            name: '成本中心',
            exact: true,
            component: React.lazy(() => import('@/pages/cost-center')),
          },
          {
            path: '/system/deps/groups',
            name: '部门分组',
            exact: true,
            hideInMenu: true,
            component: React.lazy(() => import('@/pages/dep/group')),
          },
          {
            path: '/system/evaluation-templates',
            name: '评分模板',
            exact: true,
            component: React.lazy(() => import('@/pages/evaluation/template')),
          },
          {
            path: '/system/form/list',
            name: '表单管理',
            exact: true,
            component: React.lazy(() => import('@/pages/form/list')),
          },
          {
            path: '/system/form/list/editor',
            name: '表单设计',
            exact: true,
            hideInMenu: true,
            component: React.lazy(() => import('@/pages/form/editor')),
          },
        ],
      },
    ],
  },
]
