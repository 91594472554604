// 静态路由配置，注意exact配置
import BasicLayout from '@/components/BasicLayout'
import { ProfileOutlined } from '@ant-design/icons'
import React from 'react'

export default [
  {
    path: '/app/telephone',
    component: BasicLayout,
    title: '电话管理',
    routes: [
      {
        path: '/app/telephone',
        redirect: '/app/telephone/list',
        exact: true,
      },
      {
        path: '/app/telephone/list',
        name: '电话列表',
        icon: ProfileOutlined,
        exact: true,
        component: React.lazy(() => import('@/pages/app-telephone/telephone/list')),
      },
      //       {
      //         path: '/app/telephone/detail',
      //         name: '值班手机',
      //         icon: ProfileOutlined,
      //         exact: true,
      //         component: React.lazy(() => import('@/pages/app-telephone/telephone/detail')),
      //       },
    ],
  },
]
