import React, { useState, useEffect, useCallback } from 'react'
import { Menu, Dropdown, Form, Input, Modal, message } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'
import request from '@/utils/request'

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
}

export default ({ style }) => {
  const user = JSON.parse(localStorage.getItem('user'))

  const logout = () => {
    window.localStorage.clear()
    window.location.reload()
  }
  const [editorForm] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const history = useHistory()

  const switchTenant = useCallback(async tenantId => {
    const data = await request.post(`/users/switch-tenant/${tenantId}`)
    localStorage.setItem('user', JSON.stringify(data.user))
    localStorage.setItem('token', data.token)
    localStorage.setItem('tenant', data.tenantId)
    window.location.reload()
  }, [])

  const editor = (
    <Modal
      visible={visible}
      title="重置密码"
      onOk={() => {
        editorForm.validateFields().then(async values => {
          await request.post('/users/reset-password/', values)
          setVisible(false)
          message.success('重置密码成功，请重新登陆!')

          setTimeout(() => {
            localStorage.clear()
            history.replace('/')
          }, 2000)
        })
      }}
      onCancel={() => {
        setVisible(false)
        editorForm.resetFields()
      }}
      width={960}
    >
      <Form {...layout} labelAlign="left" form={editorForm}>
        <Form.Item name="password" label="原始密码" rules={[{ required: true }]}>
          <Input type="text" />
        </Form.Item>

        <Form.Item name="new_password" label="新密码" rules={[{ required: true }]}>
          <Input placeholder="设置新的密码" type="text" />
        </Form.Item>

        <Form.Item name="new_password_confirm" label="确认新密码" rules={[{ required: true }]}>
          <Input placeholder="确认您的新密码" type="text" />
        </Form.Item>
      </Form>
    </Modal>
  )

  const menu = (
    <Menu>
      <Menu.Item key="profile" style={{ width: 150 }}>
        <Link to="/profile">个人信息</Link>
      </Menu.Item>
      <Menu.Divider />

      {user?.availableTenants.map(el => (
        <Menu.Item key={el.id}>
          <a href onClick={() => switchTenant(el.id)}>
            {el.name}
          </a>
        </Menu.Item>
      ))}
      <Menu.Divider />

      <Menu.Item key="resetpws">
        <a href onClick={() => setVisible(true)}>
          重置密码
        </a>
      </Menu.Item>
      <Menu.Item key="logout">
        <a href onClick={logout}>
          退出登陆
        </a>
      </Menu.Item>
    </Menu>
  )

  return (
    <div style={{ marginRight: 20 }}>
      {editor}
      <Dropdown overlay={menu} trigger={['click']}>
        <a onClick={e => e.preventDefault()}>
          {user.name || user.mobile} <DownOutlined />
        </a>
      </Dropdown>
    </div>
  )
}
