// 静态路由配置，注意exact配置
import React from 'react'
import BasicLayout from '@/components/BasicLayout'
import { ProfileOutlined } from '@ant-design/icons'

export default [
  {
    path: '/app/contract',
    component: BasicLayout,
    title: '合同管理',
    routes: [
      {
        path: '/app/contract',
        redirect: '/app/contract/list',
        exact: true,
      },
      {
        path: '/app/contract/list',
        name: '合同列表',
        icon: ProfileOutlined,
        exact: true,
        component: React.lazy(() => import('@/pages/app-contract/contract/list')),
      },
      {
        path: '/app/contract/list/:id',
        name: '合同详情',
        icon: ProfileOutlined,
        exact: true,
        hideInMenu: true,
        component: React.lazy(() => import('@/pages/app-contract/contract/detail')),
      },
      {
        path: '/app/contract/templates',
        name: '合同模板',
        icon: ProfileOutlined,
        exact: true,
        component: React.lazy(() => import('@/pages/app-contract/contract/template')),
      },
    ],
  },
]
