// 静态路由配置，注意exact配置
import BasicLayout from '@/components/BasicLayout'
import {
  ProfileOutlined,
  SettingOutlined,
  TeamOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons'
import React from 'react'

export default [
  {
    path: '/app/external',
    component: BasicLayout,
    title: '第三方人员管理系统',
    routes: [
      {
        path: '/app/external',
        redirect: '/app/external/staffss',
        exact: true,
      },
      {
        path: '/app/external/staffss',
        name: '人员管理',
        exact: true,
        icon: TeamOutlined,
        component: React.lazy(() => import('@/pages/app-external-staff/staff/list/index')),
      },
      {
        path: '/app/external/staff/change-records',
        name: '异动管理',
        exact: true,
        icon: UnorderedListOutlined,
        component: React.lazy(() => import('@/pages/change-record/list')),
      },
      {
        path: '/app/external/record/attendance-records',
        name: '考勤管理',
        icon: UnorderedListOutlined,
        exact: true,
        component: React.lazy(() => import('@/pages/attendance/record/index')),
      },
      {
        path: '/app/external/record/attendance-records/groups',
        name: '考勤分组',
        exact: true,
        hideInMenu: true,
        component: React.lazy(() => import('@/pages/attendance/group/index')),
      },
      {
        path: '/app/external/stats',
        name: '统计分析',
        icon: ProfileOutlined,
        routes: [
          {
            path: '/app/external/stats/statistics/employee',
            name: '人员统计',
            exact: true,
            component: React.lazy(() => import('@/pages/statistic/employee')),
          },
          {
            path: '/app/external/stats/statistics/attendance',
            name: '考勤统计',
            exact: true,
            component: React.lazy(() => import('@/pages/statistic/attendance')),
          },
        ],
      },
      {
        path: '/app/external/sys',
        name: '系统管理',
        icon: SettingOutlined,
        routes: [
          {
            path: '/app/external/sys/staff/labour/positions',
            name: '岗位管理',
            exact: true,
            component: React.lazy(() => import('@/pages/position')),
          },
          {
            path: '/app/external/sys/deps',
            name: '部门管理',
            exact: true,
            component: React.lazy(() => import('@/pages/dep/list')),
          },
        ],
      },
    ],
  },
]
