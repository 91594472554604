// 静态路由配置，注意exact配置
import React from 'react'
import BasicLayout from '@/components/BasicLayout'
import { ProfileOutlined } from '@ant-design/icons'

export default [
  {
    path: '/app/system',
    component: BasicLayout,
    title: '系统运维',
    routes: [
      {
        path: '/app/system',
        redirect: '/app/system/tenants',
        exact: true,
      },
      {
        path: '/app/system/tenants',
        name: '租户配置',
        icon: ProfileOutlined,
        exact: true,
        component: React.lazy(() => import('@/pages/app-system/tenant')),
      },
      {
        path: '/app/system/roles',
        name: '角色管理',
        exact: true,
        icon: ProfileOutlined,
        component: React.lazy(() => import('@/pages/app-system/role')),
      },
      {
        path: '/app/system/permissions',
        name: '权限管理',
        exact: true,
        icon: ProfileOutlined,
        component: React.lazy(() => import('@/pages/app-system/permission')),
      },
      {
        path: '/app/system/staffs',
        name: '用户管理',
        icon: ProfileOutlined,
        exact: true,
        component: React.lazy(() => import('@/pages/app-system/staff')),
      },
      {
        path: '/app/system/miniapp',
        name: '小程序版本',
        exact: true,
        icon: ProfileOutlined,
        component: React.lazy(() => import('@/pages/app-system/miniapp')),
      },
      {
        path: '/app/system/attendance-groups',
        name: '考勤分组',
        icon: ProfileOutlined,
        exact: true,
        component: React.lazy(() => import('@/pages/app-system/attendance-group')),
      },
      {
        path: '/app/system/files',
        name: '文件管理',
        exact: true,
        icon: ProfileOutlined,
        component: React.lazy(() => import('@/pages/app-system/file')),
      },
    ],
  },
]
